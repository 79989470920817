<template>
  <vs-row v-if="products" justify="center">
    <v-slide-group class="pa-4"  show-arrows>
      <v-slide-item v-for="item in products" v-bind:key="item.productId" style="padding: 0 1px;">
        <router-link style="text-decoration: none" :to="{ name: 'ViewProduct', params: { productId: item.productId } }">
          <v-card width="300" tile flat >
            <v-img height="100%" v-bind:src="item.productImages[0]" />
            <div style="padding: 2%;">
              <h5> {{ item.productTitle }} </h5>
              <h6> <strong>{{ item.productPrice }} BDT. </strong> </h6>
            </div>
          </v-card>
        </router-link>
      </v-slide-item>
    </v-slide-group>
  </vs-row>
</template>

<script>
export default {
  name: 'YouMayLike',
  props: ['products'],
  data() {
    return {
    };
  },
  computed: {
    productId() {
      return this.$route.params.productId;
    },
  },
  methods: {
    shuffleArray(arr) {
      arr.sort(() => Math.random() - 0.5);
    },
  },
};
</script>
