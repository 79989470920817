<template>
  <div v-if="profile && !orders">
    <h3 class="not-margin">Account Information</h3>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field v-model="firstName" :rules="[rules.required]" label="First Name" required></v-text-field>
      <v-text-field v-model="lastName" :rules="[rules.required]" label="Last Name" required></v-text-field>
      <v-text-field disabled v-model="email" label="E-mail" required></v-text-field>
      <v-text-field v-model="address" label="Address"></v-text-field>
      <v-text-field v-model="phone" label="Phone No.1"></v-text-field>
      <v-text-field v-model="phone2" label="Phone No.2"></v-text-field>
      <vs-row>
        <v-btn rounded dark @click.prevent="updateProfile()">
          Save Changes
        </v-btn>
      </vs-row>
      <vs-row style="
            padding: 10px;
            color: green;
            padding: 20px;
          ">
        <p v-if="success">{{ msg }}</p>
        <p v-if="error">{{ errMsg }}</p>
      </vs-row>
    </v-form>
  </div>
</template>
<script>
export default {
  name: 'YourProfile',
  props: ['profile', 'orders'],
  data: () => ({
    msg: 'Changes were succesfully saved.',
    error: false,
    errMsg: null,
    success: false,
    rules: {
      required: (value) => !!value || 'Required.',
    },
  }),
  computed: {
    firstName: {
      get() {
        return this.$store.state.profileFirstName;
      },
      set(payload) {
        this.$store.commit('changeFirstName', payload);
      },
    },
    lastName: {
      get() {
        return this.$store.state.profileLastName;
      },
      set(payload) {
        this.$store.commit('changeLastName', payload);
      },
    },
    email() {
      return this.$store.state.profileEmail;
    },
    address: {
      get() {
        return this.$store.state.profileAddress;
      },
      set(payload) {
        this.$store.commit('changeAddress', payload);
      },
    },
    phone: {
      get() {
        return this.$store.state.profilePhone;
      },
      set(payload) {
        this.$store.commit('changePhone', payload);
      },
    },
    phone2: {
      get() {
        return this.$store.state.profilePhone2;
      },
      set(payload) {
        this.$store.commit('changePhone2', payload);
      },
    },
  },
  methods: {
    updateProfile() {
      try {
        this.$store.dispatch('updateUserSettings');
        this.success = true;
      } catch (err) {
        this.error = true;
        this.errMsg = err.message;
      }
    },
  },
};
</script>
