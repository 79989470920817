import Vue from 'vue';
import Vuesax from 'vuesax';
import 'vuesax/dist/vuesax.css';
import vuetify from '@/plugins/vuetify';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import App from './App.vue';
import Meta from 'vue-meta';
import router from './router';
import store from './store';

Vue.config.productionTip = false;
Vue.use(Vuesax);
Vue.use(Meta);

let app;
firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    new Vue({
      vuetify,
      router,
      store,
      mode: 'history',
      base: process.env.BASE_URL,
      render: (h) => h(App),
    }).$mount('#app');
  }
});
