<template >
  <v-app :style="{height: '100%', fontFamily: 'Cinzel, serif'}" v-if="this.$store.state.productLoaded">
    <!-- <div>
      <v-system-bar v-if="saleText" dark height="50">
        <vs-row justify="center">
          <div style="color: white; text-align: center;">
            {{ saleText }}
          </div>
        </vs-row>
      </v-system-bar>
    </div> -->
    <a class="floating-button" :href="MESSENGER_LINK" target="_blank" rel="noopener"
      style="display: flex; align-items: center; text-decoration: none;">
      <v-btn rounded style="padding: 24px 14px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);" dark class="floating-button">
        <i :style="{ color: COLORS.PRIMARY, fontSize: '1.5rem', padding: '0 0.5rem' }"
          class="fa-brands fa-facebook-messenger"></i> Message us
      </v-btn>
    </a>
    <vs-row>
      <Navigation />
    </vs-row>
    <keep-alive>
      <router-view style="min-height: 100vh" />
    </keep-alive>
    <Footer />
  </v-app>
</template>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import Navigation from './components/Navigation.vue';
import Footer from './components/Footer.vue';
import { COLORS } from '@/plugins/branding.js';
import { MESSENGER_LINK } from '@/plugins/utils.js';
export default {
  name: 'App',
  components: { Navigation, Footer },
  data: () => ({
    active: 'home',
    drawer: false,
    COLORS,
    MESSENGER_LINK,
  }),
  created() {
    try {
      firebase.auth().onAuthStateChanged((user) => {
        this.$store.commit('updateUser', user);
        if (user) {
          this.$store.dispatch('getCurrentUser', user);
        }
      });
      this.$store.dispatch('getSystemVariabels');
      this.$store.dispatch('getTestimonials');
      this.$store.dispatch('getProducts');
      this.$store.dispatch('getOrders');
    } catch (e) {
      // console.log(e);
    }
  },
};
</script>

<style>
.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}
</style>
