<template>
    <div :style="$vuetify.breakpoint.smAndDown ? 'padding: 15% 5%' : 'padding: 6% 5%'">
      <PageBreadcrumbs :data="breadcrumbs" />
      <vs-row justify="center">
        <h1>All Products</h1>
      </vs-row>
      <Products :products="allProducts" />
    </div>
  </template>
  <script>
  
  import PageBreadcrumbs from '../components/PageBreadcrumbs.vue';
  import Products from '../components/Products.vue';
  
  export default {
    name: 'AllProducts',
    components: { PageBreadcrumbs, Products },
    data: () => ({
      breadcrumbs: [
        {
          text: 'Home',
          disabled: false,
          href: '/',
        },
        {
          text: 'All Products',
          disabled: true,
        },
      ],
    }),
    computed: {
      allProducts() {
        return this.$store.state.products;
      },
    },
  };
  </script>
  