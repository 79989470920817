<template>
  <div :style="$vuetify.breakpoint.smAndDown ? 'padding: 15% 5%' : 'padding: 6% 5%'">
    <PageBreadcrumbs :data="breadcrumbs" />
    <vs-row justify="center">
      <h1>Panjabi</h1>
    </vs-row>
    <Products :products="panjabis" />
  </div>
</template>
<script>

import PageBreadcrumbs from '../components/PageBreadcrumbs.vue';
import Products from '../components/Products.vue';

export default {
  name: 'Panjabis',
  components: { PageBreadcrumbs, Products },
  data: () => ({
    breadcrumbs: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
      {
        text: 'Panjabi',
        disabled: true,
      },
    ],
  }),
  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content: "Explore our exclusive collection of Panjabis, where tradition meets luxury. Each piece is designed with premium imported fabrics, featuring intricate zardozi embroidery that showcases artistry and elegance. Perfect for celebrations or special occasions, our Panjabis promise both comfort and style, making you look effortlessly sophisticated.",
        },
        {
          name: 'keywords',
          content: "Premium Panjabi, Panjabi, Wedding Panjabi, Zardozi embroidery Panjabi, Traditional men's clothing, South Asian festive wear, High-quality ethnic fashion",
        },
        {
          property: 'og:title',
          content: "SADI - where timeless elegance meets traditional flair",
        },
        {
          property: 'og:description',
          content: "Explore our exclusive collection of Panjabis, where tradition meets luxury. Each piece is designed with premium imported fabrics, featuring intricate zardozi embroidery that showcases artistry and elegance. Perfect for celebrations or special occasions, our Panjabis promise both comfort and style, making you look effortlessly sophisticated.",
        }
      ]
    }
  },
  computed: {
    panjabis() {
      return this.$store.state.panjabis;
    },
  },
};
</script>
