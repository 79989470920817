<template>
  <vs-row justify="center" align="center" :style="{ backgroundColor: colors.PRIMARY}">
    <div>
      <form>
        <v-container>
          <vs-row justify="center">
            <router-link :to="{ name: 'Login' }">Go Back? </router-link>
          </vs-row>
          <vs-row justify="center">
            <h4 :style="{ paddingBottom: '30px', paddingTop: '10px' }">
              Reset your password
            </h4>
          </vs-row>
          <vs-row justify="center">
            <vs-input v-model="email" label="Enter your email address" :style="{ padding: '5px' }">
              <template #icon>
                <span class="material-symbols-outlined"> badge </span>
              </template>
            </vs-input>
          </vs-row>
          <vs-row justify="center" :style="{ padding: '10px' }">
            <v-btn @click.prevent="resetPassword()" dark rounded :color="colors.BLACK" type="submit">
              Reset
            </v-btn>
          </vs-row>
          <vs-dialog not-close width="300px" v-model="active">
            <div class="con-content">
              <p>
                {{ msg }}
              </p>
            </div>
            <template #footer>
              <div class="con-footer">
                <v-btn rounded dark @click.prevent="closeModal()">
                  Ok
                </v-btn>
              </div>
            </template>
          </vs-dialog>
        </v-container>
      </form>
    </div>
  </vs-row>
</template>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { COLORS } from '@/plugins/branding.js';

export default {
  name: 'ForgotPassword',
  data: () => ({
    email: '',
    msg: '',
    loading: null,
    active: false,
    colors: COLORS,
  }),
  methods: {
    resetPassword() {
      this.loading = true;
      firebase
        .auth()
        .sendPasswordResetEmail(this.email)
        .then(() => {
          this.msg =
            'If your account exists, you will receive an email. Please also check your spam. Thank you.';
          this.loading = false;
          this.active = true;
        })
        .catch((err) => {
          this.msg = err.message.replace('Firebase:', '');
          this.loading = false;
          this.active = true;
        });
    },
    closeModal() {
      this.active = false;
      this.email = '';
      this.$router.push({ name: 'Login' });
    },
  },
};
</script>