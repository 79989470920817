<template>
  <div :style="$vuetify.breakpoint.smAndDown ? { padding: '6% 0%' } : { padding: '2% 5%' }">
    <div>
      <vs-row :style="{ padding: '5% 0' }">
        <vs-col v-if="currentProduct" :w="$vuetify.breakpoint.smAndDown ? 12 : 7">
          <v-carousel v-if="currentProduct" :height="$vuetify.breakpoint.smAndDown ? '550px' : '750px'"
            hide-delimiter-background hide-delimiters>
            <v-carousel-item v-for="(image, index) in currentProduct.productImages" style="height: 100%;" :key="index" :src="image"
              cover></v-carousel-item>
          </v-carousel>
        </vs-col>
        <vs-col :w="$vuetify.breakpoint.smAndDown ? 12 : 5">
          <div :style="$vuetify.breakpoint.smAndDown ? { padding: '2% 5%' } : { padding: '2% 10%' }">
            <div v-if="currentProduct && currentProduct.productTags"
              :style="{ fontSize: '15px', paddingBottom: '5px' }">
              <strong>{{ currentProduct.productTags.join(', ') }}</strong>
            </div>
            <div v-if="currentProduct" :style="{ fontSize: '30px', paddingBottom: '5px' }">
              {{ currentProduct.productTitle }}
            </div>
            <div v-if="currentProduct" :style="{ paddingBottom: '20px', fontWeight: 'bold' }">
              {{ currentProduct.productPrice }} BDT.
            </div>
            <div v-if="productSizeAndQuantity && productSizeAndQuantity[size] < 4 && productSizeAndQuantity[size] != 0"
              :style="{ paddingBottom: '20px', color: COLORS.RED, fontWeight: 'bold' }">
              Only {{ productSizeAndQuantity[size] }} left in stock.
            </div>
            <div v-if="productSizeAndQuantity && productSizeAndQuantity[size] === 0"
              :style="{ paddingBottom: '20px', fontWeight: 'bold', color: COLORS.RED }">
              Out of Stock
            </div>
            <div v-if="anyProductInStock">
              <div v-if="currentProduct && currentProduct.productColors"
                :style="{ fontSize: '14px', color: COLORS.DARK_GRAY }">
                Color: {{ selectedColor }}
              </div>
              <v-radio-group v-if="currentProduct && currentProduct.productColors" dense v-model="selectedColor" row>
                <v-radio v-for="item in currentProduct.productColors" :key="item" :color="COLOR_MAP[selectedColor]"
                  :label="item" :value="item"></v-radio>
              </v-radio-group>
              <div :style="{ color: COLORS.DARK_GRAY, fontSize: '14px' }">
                Size: {{ size }}
              </div>
              <v-radio-group dense v-model="size" row>
                <v-radio v-for="sizeLabel in sortedSizes" :key="sizeLabel" :color="COLORS.SECONDARY"
                  :label="sizeLabel" :value="sizeLabel" ></v-radio>
              </v-radio-group>
              <p v-if="error" class="error" :style="{ color: COLORS.RED}">
                {{ errorMsg }}
              </p>
              <v-row v-if="totalQuantity.length" :style="{ padding: '0 2%' }">
                <v-select v-model="quantity" :items="totalQuantity" required></v-select>
                <v-btn dark rounded @click.prevent="addToBag()">
                  Add to Bag - {{ currentProduct.productPrice }} BDT
                </v-btn>
              </v-row>
            </div>
          </div>
        </vs-col>
      </vs-row>
      <img v-if="type" :src="getSizeChartSrc(type)" width="100%" />
      <v-list :style="{ paddingBottom: '5%' }">
        <v-list-group v-if="currentProduct && currentProduct.productDescription.length > 0">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title :style="{ fontSize: '28px' }">Product Description</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item>
            {{ currentProduct.productDescription }}
          </v-list-item>
        </v-list-group>
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title :style="{ fontSize: '28px' }">Shipping and Return</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item style="font-size: 14px">
            It takes around {{ DELIVERY_TIME }} business days to deliver the product.
          </v-list-item>
          <v-list-item style="font-size: 14px">
            Shipping Fee inside Dhaka: {{ DHAKA_DELIVERY_FEE }} BDT.
          </v-list-item>
          <v-list-item style="font-size: 14px">
            Shipping Fee outside Dhaka: {{ OUTSIDE_DHAKA_DELIVERY_FEE }} BDT.
          </v-list-item>
          <v-list-item style="font-size: 14px">
            Please inspect the product carefully in front of the delivery person after your payment is completed
          </v-list-item>
          <v-list-item style="font-size: 14px">
            You may try it on, and if it doesn’t fit, you can return it by covering the delivery charge.
          </v-list-item>
          <v-list-item style="font-size: 14px">
            Returns, exchanges, and refunds will not be accepted after the delivery is complete
          </v-list-item>
          <v-list-item style="font-size: 14px">
            Return or exchange or refunds will not be accepted for Pre Ordered Custom Items.
          </v-list-item>
        </v-list-group>
      </v-list>
      <div v-if="bestSellers.length" :style="{ fontSize: '28px', paddingBottom: '5px', textAlign: 'center' }">
        You May Also Like
        <YouMayLike :products="bestSellers" />
      </div>
    </div>
    <v-alert v-if="prodQuantityInBag" class="floating-alert" dense rounded :color="COLORS.SECONDARY" colored-border
      elevation="4" icon="mdi-sale" border="left">
      {{ prodQuantityInBag }} {{ size }} {{currentProduct.productTitle}} currently in bag.
    </v-alert>
  </div>
</template>

<script>
import YouMayLike from '../components/YouMayLike.vue';
import { COLORS } from '@/plugins/branding.js';
import { DHAKA_DELIVERY_FEE, OUTSIDE_DHAKA_DELIVERY_FEE, COLOR_MAP, DELIVERY_TIME } from '@/plugins/utils.js';

export default {
  name: 'ViewProduct',
  components: { YouMayLike },
  data() {
    return {
      type: null,
      currentProduct: null,
      selectedColor: null,
      size: 'S',
      quantity: 1,
      totalQuantity: [],
      error: false,
      errorMsg: '',
      COLORS,
      DELIVERY_TIME,
      DHAKA_DELIVERY_FEE,
      OUTSIDE_DHAKA_DELIVERY_FEE,
      COLOR_MAP
    };
  },
  computed: {
    productId() {
      return this.$route.params.productId;
    },
    bestSellers() {
      return this.$store.state.bestSellers.filter(
        (post) => post.productId !== this.productId && post.productQuantity > 0
      );
    },
    productSizeAndQuantity: {
      get() {
        return this.$store.state.productSizeAndQuantity || {};
      },
    },
    sortedSizes() {
      const order = ['S', 'M', 'L', 'XL', 'XXL'];
      return Object.keys(this.productSizeAndQuantity).sort((a, b) => {
        return order.indexOf(a) - order.indexOf(b);
      });
    },
    prodQuantityInBag() {
      return this.$store.state.bag
        .filter((post) => post.productId === this.productId && post.size === this.size)
        .reduce((total, post) => total + post.quantity, 0);
    },
    anyProductInStock() {
      return Object.values(this.productSizeAndQuantity).some(quantity => quantity > 0);
    }
  },
  mounted() {
    this.loadProduct();
  },
  watch: {
    productId() {
      if (this.productId) {
        this.loadProduct();
      }
    },
    prodQuantityInBag() {
      this.loadQuantity();
    },
    size() {
      this.loadQuantity();
    }
  },
  methods: {
    getSizeChartSrc(type) {
      return require(`../assets/${type}SizeChart.png`);
    },
    loadQuantity() {
      if (this.productSizeAndQuantity && this.size) {
        this.totalQuantity = Array.from(
          { length: this.productSizeAndQuantity[this.size] - this.prodQuantityInBag },
          (_, i) => i + 1
        );
      }
    },
    shuffleArray(arr) {
      arr.sort(() => Math.random() - 0.5);
    },
    async loadProduct() {
      if (this.$store.state.productLoaded) {
        this.currentProduct = await this.$store.state.products.filter((post) => post.productId === this.$route.params.productId)[0];
      }
      this.$store.commit('setProductState', this.currentProduct);
      if (this.currentProduct && this.currentProduct.productColors) {
        this.selectedColor = this.currentProduct.productColors[0];
      }
      if (this.currentProduct.productTags.includes('Panjabi')) {
        this.type = "panjabi";
      }
      else if (this.currentProduct.productTags.includes('Waist Coat')) {
        this.type = "waistCoat";
      }
      else if (this.currentProduct.productTags.includes('Shirt')) {
        this.type = "shirt";
      }
      this.loadQuantity();
    },
    addToBag() {
      if (this.currentProduct.productColors.length > 0 && this.selectedColor === null) {
        this.error = true;
        this.errorMsg = 'All fields are required!';
        return;
      }
      if (this.quantity !== null && this.size !== null) {
        this.error = false;
        this.loadQuantity();
        const data = {
          productId: this.currentProduct.productId,
          selectedColor: this.selectedColor,
          productTitle: this.currentProduct.productTitle,
          productPrice: this.currentProduct.productPrice,
          size: this.size,
          quantity: this.quantity,
          productImage: this.currentProduct.productImages[0],
        };
        this.$store.commit('addToBag', data);
      } else {
        this.error = true;
        this.errorMsg = 'All fields are required!';
        setTimeout(() => {
          this.error = false;
        }, 5000);
      }
    },
  },
};
</script>

<style>
.floating-alert {
  position: fixed;
  bottom: 70px;
  right: 20px;
  z-index: 1000;
  font-weight: bold;
}
</style>