<template>
  <div>
    <v-navigation-drawer v-model="drawer" :style="{ backgroundColor: colors.PRIMARY }"
      v-if="$vuetify.breakpoint.smAndDown" fixed temporary width="80%" min-height="100vh">
      <router-link :style="{ textDecoration: 'none', }" :to="{ name: 'Home' }">
        <vs-row justify="center">
          <img width="120px" src="../assets/logo.png" />
        </vs-row>
      </router-link>
      <v-divider></v-divider>
      <router-link :style="{ textDecoration: 'none', color: colors.BLACK }" :to="{ name: 'Panjabi' }">
        <vs-row :style="{ paddingLeft: '15px' }">
          <p :style="{ paddingLeft: '10px', fontSize: '18px', fontWeight: '500' }">Panjabi</p>
        </vs-row>
      </router-link>
      <router-link :style="{ textDecoration: 'none', color: colors.BLACK }" :to="{ name: 'WaistCoat' }">
        <vs-row :style="{ paddingLeft: '15px' }">
          <p :style="{ paddingLeft: '10px', fontSize: '18px', fontWeight: '500' }">Waist Coat</p>
        </vs-row>
      </router-link>
      <router-link :style="{ textDecoration: 'none', color: colors.BLACK }" :to="{ name: 'Shirt' }">
        <vs-row :style="{ paddingLeft: '15px' }">
          <p :style="{ paddingLeft: '10px', fontSize: '18px', fontWeight: '500' }">Shirt</p>
        </vs-row>
      </router-link>
      <v-divider></v-divider>
      <router-link v-if="!user" :style="{ textDecoration: 'none', color: colors.BLACK }" :to="{ name: 'Login' }">
        <vs-row :style="{ paddingLeft: '15px' }">
          <p :style="{ paddingLeft: '10px', fontSize: '18px', fontWeight: '500' }">Sign In</p>
        </vs-row>
      </router-link>
      <router-link v-if="user" :style="{ textDecoration: 'none', color: colors.BLACK }" :to="{ name: 'Profile' }">
        <vs-row :style="{ paddingLeft: '15px' }">
          <p :style="{ paddingLeft: '10px', fontSize: '18px', fontWeight: '500' }">Profile</p>
        </vs-row>
      </router-link>
      <router-link :style="{ textDecoration: 'none', color: colors.BLACK }" :to="{ name: 'Support' }">
        <vs-row :style="{ paddingLeft: '15px' }">
          <p :style="{ paddingLeft: '10px', fontSize: '18px', fontWeight: '500' }">Support</p>
        </vs-row>
      </router-link>
    </v-navigation-drawer>
    <v-app-bar :style="{ backgroundColor: colors.PRIMARY }" square flat fixed>
      <div
        :style="{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', position: 'relative' }">
        <!-- left -->
        <div :style="{ display: 'flex', alignItems: 'center' }">
          <router-link v-if="$vuetify.breakpoint.mdAndUp" :style="{ textDecoration: 'none' }" to="/">
            <img :width="'100px'" src="../assets/logo.png" />
          </router-link>
          <vs-row style="padding-left: 18px;" justify="left" v-if="$vuetify.breakpoint.mdAndUp">
            <v-tabs optional>
              <router-link :style="{ textDecoration: 'none' }" :to="{ name: 'Panjabi' }">
                <v-tab :style="{ color: colors.BLACK, height: '100%' }">
                  Panjabi
                </v-tab>
              </router-link>
              <router-link :style="{ textDecoration: 'none' }" :to="{ name: 'WaistCoat' }">
                <v-tab :style="{ color: colors.BLACK, height: '100%' }">
                  Waist Coat
                </v-tab>
              </router-link>
              <router-link :style="{ textDecoration: 'none' }" :to="{ name: 'Shirt' }">
                <v-tab :style="{ color: colors.BLACK, height: '100%' }">
                  Shirt
                </v-tab>
              </router-link>
            </v-tabs>
          </vs-row>
          <v-app-bar-nav-icon :color="colors.BLACK" v-if="$vuetify.breakpoint.smAndDown"
            @click="drawer = true"></v-app-bar-nav-icon>
          <router-link v-if="$vuetify.breakpoint.smAndDown" :style="{ textDecoration: 'none' }" to="/">
            <img :width="'80px'" src="../assets/logo.png" />
          </router-link>
        </div>
        <!-- middle -->
        <!-- <div :style="{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }">
          <router-link :style="{ textDecoration: 'none' }" to="/">
            <img :width="'120px'" src="../assets/logo.png" />
          </router-link>
        </div> -->
        <!-- right -->
        <div :style="{ display: 'flex', alignItems: 'center' }">
          <!-- profile -->
          <v-speed-dial :right="right" :direction="direction" :open-on-hover="hover" :transition="transition">
            <template v-slot:activator>
              <div>
                <span class="material-symbols-outlined">
                  account_circle
                </span>
              </div>
            </template>
            <router-link :style="{ textDecoration: 'none' }" :to="{ name: 'Login' }">
              <v-btn rounded v-if="!user" :color="colors.PRIMARY">
                Sign In
              </v-btn>
            </router-link>
            <router-link :style="{ textDecoration: 'none' }" :to="{ name: 'Profile' }">
              <v-btn rounded v-if="user" :color="colors.PRIMARY">
                Profile
              </v-btn>
            </router-link>
            <router-link :style="{ textDecoration: 'none' }" :to="{ name: 'AdminPanel' }">
              <v-btn rounded v-if="admin" :color="colors.PRIMARY">
                Admin Panel
              </v-btn>
            </router-link>
            <v-btn rounded @click="signOut()" v-if="user" :color="colors.PRIMARY">
              Sign Out
            </v-btn>
          </v-speed-dial>
          <!-- cart -->
          <div @click.stop="bagToggle = !bagToggle">
            <span class="material-symbols-outlined"> shopping_bag </span>
          </div>
        </div>
      </div>
    </v-app-bar>
    <v-navigation-drawer :style="{ backgroundColor: colors.PRIMARY }" v-model="bagToggle" right fixed temporary
      :width="$vuetify.breakpoint.smAndDown ? '80%' : '40%'" min-height="100vh">
      <div v-if="bagEmpty" class="d-flex flex-column align-items-center" :style="{ padding: '5% 10%' }">
        <div :style="{ fontSize: '32px', padding: '1%' }">Empty Bag!</div>
        <div :style="{ fontSize: '24px', padding: '1%' }">Let's get you shopping!</div>
        <router-link :style="{ textDecoration: 'none', padding: '5%' }" :to="{ name: 'AllProducts' }">
          <v-btn dark rounded :style="{ fontSize: '14px', }">
            Start Shopping
          </v-btn>
        </router-link>
      </div>
      <div v-if="!bagEmpty">
        <Bag :products="bag" :color="colors.PRIMARY" :height="'150px'" />
        <div :style="{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', padding: '5%' }">
          <div :style="{ padding: '2%' }">Total: {{ totalPrice }} BDT</div>
          <router-link :style="{ textDecoration: 'none' }" :to="{ name: 'Checkout' }">
            <v-btn dark rounded :style="{ fontSize: '14px' }">
              Review and Checkout
            </v-btn>
          </router-link>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import Bag from './Bag.vue';
import { COLORS } from '@/plugins/branding.js';

export default {
  name: 'Navigation',
  components: { Bag },
  data() {
    return {
      drawer: false,
      bagToggle: false,
      direction: 'bottom',
      hover: true,
      right: true,
      colors: COLORS,
      transition: 'slide-x-reverse-transition',
    };
  },
  methods: {
    signOut() {
      firebase.auth().signOut();
      this.$router.push({ name: 'Home' });
      this.$store.commit('removeAdmin');
      window.location.reload();
    },
    removeItem() {
      this.$store.commit('removeItemFromBag', this.bag);
    },
  },
  watch: {
    bag() {
      this.bagToggle = true;
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    admin() {
      return this.$store.state.profileAdmin;
    },
    bag() {
      return this.$store.state.bag;
    },
    allProducts() {
      return this.$store.state.products;
    },
    totalPrice() {
      let value = 0;
      this.bag.forEach((item) => {
        value += Number(item.productPrice) * item.quantity;
      });
      return value;
    },
    bagEmpty() {
      return this.bag.length === 0;
    },
  },
};
</script>