<template>
  <div :style="$vuetify.breakpoint.smAndDown ? 'padding: 15% 5%' : 'padding: 6% 5%'
    ">
    <PageBreadcrumbs :data="breadcrumbs" />
    <vs-row justify="center">
      <h1>Best Sellers</h1>
    </vs-row>
    <Products :products="bestSellers" />
  </div>
</template>
<script>

import PageBreadcrumbs from '../components/PageBreadcrumbs.vue';
import Products from '../components/Products.vue';

export default {
  name: 'BestSellers',
  components: { PageBreadcrumbs, Products },
  data: () => ({
    breadcrumbs: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
      {
        text: 'Best Sellers',
        disabled: true,
      },
    ],
  }),
  computed: {
    bestSellers() {
      return this.$store.state.bestSellers;
    },
  },
};
</script>
