<template>
  <div style="font-size: 12px;">
    <vs-row>
      <vs-col v-for="item in orders" v-bind:key="item.orderId" style="padding: 0.5%" :w="$vuetify.breakpoint.smAndDown ? 12 : 6">
        <v-card tile :style="`padding: 2%; background-color: ${COLORS.PRIMARY}`">
          <vs-row style="height: 100%">
            <vs-row justify="right">
              <v-btn v-if="user" text rounded>Order Status: {{ item.status }}.</v-btn>
            </vs-row>
            <vs-col w="4">
              <div>
                <div><strong>Order Id: </strong> {{ item.orderId }}</div>
                <div><strong>Order Date: </strong> {{ item.orderDate }}</div>
                <div><strong>Order Total: </strong> {{ item.orderTotal }} BDT.</div>
                <div v-if="!user"><strong>Name: </strong> {{ item.firstName }}</div>
                <div v-if="!user"><strong>Phone: </strong> {{ item.phone }}</div>
                <div v-if="!user && item.phone2"><strong>Alternate phone: </strong> {{ item.phone2 }}</div>
                <div><strong>Shipping Address: </strong> {{ item.address }}</div>
                <div v-if="item.paymentMethod"><strong>Payment Method: </strong> {{ item.paymentMethod }}</div>
                <div v-if="item.bkashNumber"><strong>Bkash Number/Id: </strong> {{ item.bkashNumber }}</div>
              </div>
            </vs-col>
            <vs-col w="8">
              <v-slide-group show-arrows>
                <v-slide-item v-for="prod in item.orderedProducts" v-bind:key="prod.productId"
                  :style="{padding: '0 12px'}">
                  <router-link style="text-decoration: none"
                    :to="{ name: 'ViewProduct', params: { productId: prod.productId } }">
                    <v-card width="100" height="100%"  tile flat :style="{padding: '0 1px', backgroundColor: COLORS.PRIMARY}">
                      <v-img width="100%" height="100" v-bind:src="prod.productImage" />
                      <div><strong>Title: </strong> {{ prod.productTitle }}</div>
                      <div><strong>Price: </strong> {{ prod.productPrice }} BDT.</div>
                      <div><strong>Color: </strong> {{ prod.color }}</div>
                      <div><strong>Quantity: </strong>{{ prod.quantity }}</div>
                      <div><strong>Size: </strong>{{ prod.size }}</div>
                    </v-card>
                  </router-link>
                </v-slide-item>
              </v-slide-group>
            </vs-col>
            <vs-row style="padding: 2% 0;" justify="right">
                <v-btn v-if="!user" :disabled="disabled" dark :dark="!disabled" rounded
                  @click.prevent="updateCollection(item.orderId)" v-text="status">
                </v-btn>
              </vs-row>
          </vs-row>
        </v-card>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import { COLORS } from '@/plugins/branding.js';

export default {
  name: 'Orders',
  props: ['orders', 'user', 'status', 'commit', 'disabled'],
  data() {
    return {
      COLORS,
    };
  },
  methods: {
    updateCollection(orderId) {
      try {
        this.$store.dispatch(this.commit, orderId);
      } catch (err) {
        // console.log(err);
      }
    },
  },
};
</script>