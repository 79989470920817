// Constants for colors used in SADI project
export const COLORS = {
    PRIMARY: '#FFFFFF',
    SECONDARY: '#000000',
    BLACK: '#000000',
    WHITE: '#FFFFFF',
    GRAY: '#d0d4d9',
    DARK_GRAY: '#7f8c8d',
    GREEN: '#00b894',
    RED: '#ff7675',
    FACEBOOK: '#3b5998',
    GOOGLE: '#db4437',
    INSTAGRAM: '#e1306c',
};