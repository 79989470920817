import { COLORS } from '@/plugins/branding.js';

export function toggleLoading(context, isLoading) {
    if (isLoading) {
      const loading = context.$vs.loading({
        type: 'square',
        color: COLORS.SECONDARY,
      });
      context.hasOpenLoading = true;
      context.loadingInstance = loading;
    } else {
      if (context.loadingInstance) {
        context.loadingInstance.close();
        context.hasOpenLoading = false;
      }
    }
  }