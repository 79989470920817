<template>
  <div :style="$vuetify.breakpoint.smAndDown ? 'padding: 15% 5%' : 'padding: 6% 5%'
    ">
    <PageBreadcrumbs :data="breadcrumbs" />
    <vs-row justify="center">
      <h1>Waist Coats</h1>
    </vs-row>
    <Products :products="waistCoats" />
  </div>
</template>
<script>

import PageBreadcrumbs from '../components/PageBreadcrumbs.vue';
import Products from '../components/Products.vue';

export default {
  name: 'WaistCoats',
  components: { PageBreadcrumbs, Products },
  data: () => ({
    breadcrumbs: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
      {
        text: 'Waist Coat',
        disabled: true,
      },
    ],
  }),
  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content: "Elevate your style with our collection of luxurious waistcoats. Made from the finest fabrics and adorned with intricate zardozi handwork, each waistcoat is a masterpiece. Whether you're dressing up for a formal event or adding a touch of elegance to your everyday attire, our waistcoats offer the perfect balance of tradition and modernity.",
        },
        {
          name: 'keywords',
          content: "Luxury waistcoat, Coaty, Baghdala, Wedding Collection, High-quality fabric waistcoat, Zardozi hand work waistcoat, Traditional men's waistcoat, Formal ethnic wear",
        },
        {
          property: 'og:title',
          content: "SADI - where timeless elegance meets traditional flair",
        },
        {
          property: 'og:description',
          content: "Elevate your style with our collection of luxurious waistcoats. Made from the finest fabrics and adorned with intricate zardozi handwork, each waistcoat is a masterpiece. Whether you're dressing up for a formal event or adding a touch of elegance to your everyday attire, our waistcoats offer the perfect balance of tradition and modernity.",
        }
      ]
    }
  },
  computed: {
    waistCoats() {
      return this.$store.state.waistCoats;
    },
  },
};
</script>
