<template>
  <div :style="$vuetify.breakpoint.smAndDown ? 'padding: 15% 5%' : 'padding: 6% 5%'
    ">
    <PageBreadcrumbs :data="breadcrumbs" />
    <vs-row justify="center">
      <h1>
        {{ this.$store.state.collectionName }}
      </h1>
      
    </vs-row>
    <Products :products="collection" />
  </div>
</template>
<script>

import PageBreadcrumbs from '../components/PageBreadcrumbs.vue';
import Products from '../components/Products.vue';
import { BRAND_NAME } from '@/plugins/utils.js';

export default {
  name: 'Collection',
  components: { PageBreadcrumbs, Products },
  data: () => ({
    breadcrumbs: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
      {
        text: 'Collection',
        disabled: true,
      },
    ],
    BRAND_NAME,
  }),
  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content: "Introducing the Legends of Zardozi — a luxurious collection that redefines traditional elegance. Each piece in this lineup is a celebration of heritage craftsmanship, adorned with intricate Zardozi embroidery and crafted for those who appreciate the art of detail. Inspired by the rich legacy of royal attire, our collection brings together timeless charm and contemporary style, making it perfect for weddings, festive celebrations, and special occasions.",
        },
        {
          name: 'keywords',
          content: "Zardozi embroidery, traditional elegance, heritage craftsmanship, royal attire, timeless charm, custom order options, personalized collection, luxury, wedding and festive collection, hand-embroidered designs, Zardozi Panjabi, Kurta",
        },
        {
          property: 'og:title',
          content: "SADI - New Collections",
        },
        {
          property: 'og:description',
          content: "Introducing the Legends of Zardozi — a luxurious collection that redefines traditional elegance. Each piece in this lineup is a celebration of heritage craftsmanship, adorned with intricate Zardozi embroidery and crafted for those who appreciate the art of detail. Inspired by the rich legacy of royal attire, our collection brings together timeless charm and contemporary style, making it perfect for weddings, festive celebrations, and special occasions.",
        }
      ]
    }
  },
  computed: {
    collection() {
      return this.$store.state.newCollection;
    },
  },
};
</script>
