import Vue from 'vue';
import VueRouter from 'vue-router';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import store from '../store';

import { BRAND_NAME } from '../plugins/utils';
import Home from '../views/Home.vue';
import AllProducts from '../views/AllProducts.vue';
import Panjabi from '../views/Panjabi.vue';
import BestSellers from '../views/BestSellers.vue';
import Shirt from '../views/Shirt.vue';
import WaistCoat from '../views/WaistCoat.vue';
import Collection from '../views/Collection.vue';
import Login from '../views/Login.vue';
import Register from '../views/Register.vue';
import ForgotPassword from '../views/ForgotPassword.vue';
import Profile from '../views/Profile.vue';
import AdminPanel from '../views/AdminPanel.vue';
import ViewProduct from '../views/ViewProduct.vue';
import EditProduct from '../views/EditProduct.vue';
import Checkout from '../views/Checkout.vue';
import Support from '../views/Support.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Home',
      requiresAuth: false,
    },
  },
  {
    path: '/panjabi',
    name: 'Panjabi',
    component: Panjabi,
    meta: {
      title: 'Panjabi',
      requiresAuth: false,
    },
  },
  {
    path: '/allProducts',
    name: 'AllProducts',
    component: AllProducts,
    meta: {
      title: 'All Products',
      requiresAuth: false,
    },
  },
  {
    path: '/bestSellers',
    name: 'BestSellers',
    component: BestSellers,
    meta: {
      title: 'Best Sellers',
      requiresAuth: false,
    },
  },
  {
    path: '/collection',
    name: 'Collection',
    component: Collection,
    meta: {
      title: 'Collection',
      requiresAuth: false,
    },
  },
  {
    path: '/shirt',
    name: 'Shirt',
    component: Shirt,
    meta: {
      title: 'Shirt',
      requiresAuth: false,
    },
  },
  {
    path: '/waistCoat',
    name: 'WaistCoat',
    component: WaistCoat,
    meta: {
      title: 'Waist Coat',
      requiresAuth: false,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: 'Login',
      requiresAuth: false,
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      title: 'Register',
      requiresAuth: false,
    },
  },
  {
    path: '/forgotPassword',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      title: 'Forgot Password',
      requiresAuth: false,
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      title: 'Profile',
      requiresAuth: true,
      requiresAdmin: false,
    },
  },
  {
    path: '/adminPanel',
    name: 'AdminPanel',
    component: AdminPanel,
    meta: {
      title: 'AdminPanel',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/viewProduct/productId=:productId',
    name: 'ViewProduct',
    component: ViewProduct,
    meta: {
      title: 'Product',
      requiresAuth: false,
    },
  },
  {
    path: '/editProduct/productId=:productId',
    name: 'EditProduct',
    component: EditProduct,
    meta: {
      title: 'Edit Product',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: Checkout,
    meta: {
      title: 'Checkout',
      requiresAuth: false,
    },
  },
  {
    path: '/support',
    name: 'Support',
    component: Support,
    meta: {
      title: 'Support',
      requiresAuth: false,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} | ${BRAND_NAME}`;
  next();
});

router.beforeEach(async (to, from, next) => {
  const user = firebase.auth().currentUser;
  if (to.matched.some((res) => res.meta.requiresAuth)) {
    if (user) {
      if (to.matched.some((res) => res.meta.requiresAdmin)) {
        if (store.state.profileAdmin) {
          return next();
        }
        return next({ name: 'Home' });
      }
      return next();
    }
    return next({ name: 'Home' });
  }
  return next();
});

export default router;
