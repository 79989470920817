<template>
  <div :style="$vuetify.breakpoint.smAndDown ? 'padding: 15% 5%' : 'padding: 6% 5%'">
    <div>
      <vs-row :style="{ padding: '5% 0' }">
        <vs-col v-if="currentProduct" :w="$vuetify.breakpoint.smAndDown ? 12 : 7">
          <div v-if="images && images.length">
            <v-row>
              <v-col v-for="(image, index) in images" :key="index" cols="4">
                <v-card>
                  <v-img :src="image" class="mx-2" max-width="100%"></v-img>
                  <v-card-actions>
                    <v-btn depressed dark rounded :color="COLORS.RED" @click="deleteImage(image, index)">Delete</v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </div>
          <v-divider></v-divider>
          <div v-if="files && files.length">
            <v-row>
              <v-col v-for="(image, index) in files" :key="index" cols="4">
                <v-img :src="image.url" class="mx-2" max-width="100%"></v-img>
              </v-col>
            </v-row>
          </div>
        </vs-col>
        <vs-col :w="$vuetify.breakpoint.smAndDown ? 12 : 4">
          <div :style="$vuetify.breakpoint.smAndDown ? 'padding: 2% 3%' : 'padding: 2% 10%'">
            <v-form>
              <v-text-field v-model="title" label="Add Title" :rules="[rules.required]">
              </v-text-field>
              <v-textarea v-model="description" label="Add Description"></v-textarea>
              <v-text-field v-model.number="price" :rules="[rules.required]" label="Add Price">
              </v-text-field>
              <div style="display: flex; flex-direction: row; align-items: center">
                <div v-for="(quantity, size) in productSizeAndQuantity" :key="size"
                  style="flex: 1; margin-right: 15px;">
                  <v-text-field v-model.number="productSizeAndQuantity[size]" :label="`${size}`" :rules="[rules.required]"
                    type="number"></v-text-field>
                </div>
              </div>
              <v-combobox dense :items="availableColors" v-model="colors" label="Add Colors" chips multiple>
                <template slot="item" slot-scope="data">
                  <v-list-item-content>
                    <div> {{ data.item }}</div>
                  </v-list-item-content>
                  <v-list-item-action>
                    <div :style="{ width: '28px', height: '28px', backgroundColor: COLOR_MAP[data.item] }"></div>
                  </v-list-item-action>
                </template>
              </v-combobox>
              <v-select dense :items="tagsList" v-model="tags" label="Add Tags" chips multiple>
              </v-select>
              <v-file-input multiple label="Add Images" v-model="files" prepend-icon="mdi-camera"></v-file-input>
              <v-btn dark rounded @click.prevent="updateProduct()">
                Save Changes
              </v-btn>
              <vs-row v-if="error || success" style="
                      padding: 10px;
                      padding: 20px;">
                <p :style="{ color: COLORS.RED }" v-if="error">
                  {{ this.errorMsg }}
                </p>
                <p :style="{ color: COLORS.GREEN }" v-if="success">
                  {{ this.msg }}
                </p>
              </vs-row>
            </v-form>
          </div>
        </vs-col>
      </vs-row>
      <div v-if="allProducts.length" style="font-size: 28px; ">Edit Existing Products:</div>
      <EditProducts style="max-height: 70vh; overflow-y: auto;" :products="allProducts" />
    </div>
  </div>
</template>

<script>
import EditProducts from '../components/EditProducts.vue';
import { COLORS } from '@/plugins/branding.js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import db from '../firebase/firebaseInit';
import { AVAILABLE_COLORS, TAGS, COLOR_MAP } from '../plugins/utils';
import { toggleLoading } from '@/components/js/loading.js';
import { resizeImage } from '@/components/js/imageHelper.js';

export default {
  name: 'EditProduct',
  components: { EditProducts },
  data() {
    return {
      currentProduct: null,
      COLORS,
      files: [],
      error: false,
      success: false,
      msg: '',
      errorMsg: '',
      tagsList: TAGS,
      rules: {
        required: (value) => !!value || 'Required.',
      },
      availableColors: AVAILABLE_COLORS,
      COLOR_MAP
    };
  },
  computed: {
    productId() {
      return this.$route.params.productId;
    },
    title: {
      get() {
        return this.$store.state.productTitle;
      },
      set(payload) {
        this.$store.commit('updateProductTitle', payload);
      },
    },
    description: {
      get() {
        return this.$store.state.productDescription;
      },
      set(payload) {
        this.$store.commit('updateProductDescription', payload);
      },
    },
    price: {
      get() {
        return this.$store.state.productPrice;
      },
      set(payload) {
        this.$store.commit('updateProductPrice', payload);
      },
    },
    productSizeAndQuantity: {
      get() {
        return this.$store.state.productSizeAndQuantity;
      },
      set(payload) {
        this.$store.commit('updateProductSizeAndQuantity', payload);
      },
    },
    colors: {
      get() {
        return this.$store.state.productColors;
      },
      set(payload) {
        this.$store.commit('updateProductColors', payload);
      },
    },
    tags: {
      get() {
        return this.$store.state.productTags;
      },
      set(payload) {
        this.$store.commit('updateProductTags', payload);
      },
    },
    images: {
      get() {
        return this.$store.state.productImages;
      },
      set(payload) {
        this.$store.commit('updateProductImages', payload);
      },
    },
    allProducts() {
      return this.$store.state.products.filter(
        (post) => post.productId !== this.productId
      );
    },
  },
  mounted() {
    this.loadProduct();
  },
  watch: {
    productId() {
      this.loadProduct();
    },
    files(newFiles) {
      this.files = newFiles.map(file => ({
        file,
        url: URL.createObjectURL(file)
      }));
    }
  },
  methods: {
    async fileExists(docRef) {
      try {
        await docRef.getMetadata();
        return true;
      } catch (error) {
        if (error.code === 'storage/object-not-found') {
          return false;
        }
        throw error;
      }
    },
    async deleteImage(image, index) {
      try {
        this.toggleLoading(true);
        const imageRef = firebase.storage().refFromURL(image);
        await imageRef.delete();
        const db = firebase.firestore();
        const productDoc = db.collection('products').doc(this.productId);
        await productDoc.update({
          images: firebase.firestore.FieldValue.arrayRemove(image)
        });
        this.images.splice(index, 1);
        this.msg = 'Image successfully deleted.';
        this.toggleLoading(false);
        this.success = true;
        setTimeout(() => {
          this.success = false;
        }, 5000);
      } catch (err) {
        this.errorMsg = err.message;
        this.toggleLoading(false);
        this.error = true;
        setTimeout(() => {
          this.error = false;
        }, 5000);
      }
    },
    toggleLoading(isLoading) {
      toggleLoading(this, isLoading);
    },
    async loadProduct() {
      if (this.$store.state.productLoaded) {
        this.currentProduct = await this.$store.state.products.filter(
          (post) => post.productId === this.$route.params.productId
        );
      }
      this.$store.commit('setProductState', this.currentProduct[0]);
    },
    async updateProduct() {
      try {
        this.toggleLoading(true);
        const dataBase = await db.collection('products').doc(this.productId);
        const storageRef = firebase.storage().ref();
        const uploadPromises = this.files.map(async ({ file }, index) => {
          let uniqueIndex = index;
          let docRef = storageRef.child(`documents/ProductPhotos/${this.title}_${uniqueIndex}`);
          let exists = await this.fileExists(docRef);
          while (exists) {
            uniqueIndex++;
            docRef = storageRef.child(`documents/ProductPhotos/${this.title}_${uniqueIndex}`);
            exists = await this.fileExists(docRef);
          }
          const resizedFile = await resizeImage(file);
          await docRef.put(resizedFile);
          const url = await docRef.getDownloadURL();
          this.images.push(url);
        });
        if (uploadPromises && uploadPromises.length) {
          await Promise.all(uploadPromises);
        }
        await dataBase.update({
          title: this.title,
          price: this.price,
          colors: this.colors,
          description: this.description,
          quantity: this.productSizeAndQuantity,
          tags: this.tags,
          images: this.images,
        });
        this.msg = 'The product has now been updated.';
        this.files = [];
        this.success = true;
        this.toggleLoading(false);
        setTimeout(() => {
          this.success = false;
        }, 5000);
      } catch (err) {
        this.errorMsg = err.message;
        this.toggleLoading(false);
        this.error = true;
        setTimeout(() => {
          this.error = false;
        }, 5000);
      }
    },
  },
};
</script>
