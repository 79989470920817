// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';

const firebaseConfig = {
  apiKey: "AIzaSyDGci6bLNUYLkeTlSvw8JZA94pINhyI6Vg",
  authDomain: "sadi-bd.firebaseapp.com",
  projectId: "sadi-bd",
  storageBucket: "sadi-bd.appspot.com",
  messagingSenderId: "756019921860",
  appId: "1:756019921860:web:de8b19f291c78d1d1a1a91",
  measurementId: "G-S4ZGJ4739Y"
};

// Use this to initialize the firebase App
const firebaseApp = firebase.initializeApp(firebaseConfig);

export default firebaseApp.firestore();
