<template>
  <v-breadcrumbs :style="{ textDecoration: 'none', color: colors.BLACK }" :items="data" divider="/"></v-breadcrumbs>
</template>

<script>
import { COLORS } from '@/plugins/branding.js';

export default {
  name: 'PageBreadcrumbs',
  props: ['data'],
  data: () => ({
    colors: COLORS,
  }),
};
</script>