<template>
  <vs-row style="padding: 5%" justify="left">
    <h3>Shopping Bag</h3>
    <vs-row v-for="item in products" v-bind:key="item.productId + item.size" justify="left">
      <v-card :color="color" tile flat style="padding: 2% 0">
        <router-link style="text-decoration: none; color: inherit" :to="{ name: 'ViewProduct', params: { productId: item.productId }}">
          <vs-row>
            <vs-col w="4"> <v-img :height="height" v-bind:src="item.productImage" /></vs-col>
            <vs-col style="padding-left: 5%" w="8">
              <div style="font-size: 17px; font-weight: bold"> {{ item.productTitle }} </div>
              <div style="font-size: 15px"> {{ item.selectedColor }} | {{ item.size }} </div>
              <div style="font-size: 15px">Quanity: {{ item.quantity }}</div>
              <div style="font-size: 15px; font-weight: bold"> {{ item.productPrice }} BDT. </div>
              <vs-row justify="right">
                <v-btn style="font-size: 15px" dark rounded @click.prevent="removeItem(item)">
                  Remove
                </v-btn>
              </vs-row>
            </vs-col>
          </vs-row>
        </router-link>
      </v-card>
    </vs-row>
  </vs-row>
</template>

<script>
export default {
  name: 'Bag',
  props: ['products', 'color', 'height'],
  methods: {
    removeItem(item) {
      this.$store.commit('removeItemFromBag', item);
    },
  },
};
</script>
