import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import { COLORS } from '@/plugins/branding.js';

Vue.use(Vuetify);

const opts = {
    theme: {
        dark: false,
        themes: {
          light: {
            primary: COLORS.SECONDARY,
          }
        }
      }
};

export default new Vuetify(opts);

