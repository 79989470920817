<template>
  <div>
    <v-sheet :style="{ backgroundColor: COLORS.PRIMARY, width: '100%' }">
      <div style="padding: 20px">
        <v-row style="padding: 50px" class="text-center">
          <v-col style="padding: 10px" :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
            <router-link :style="{ textDecoration: 'none', color: 'inherit' }" :to="{ name: 'Support' }">
              <h4>Support</h4>
              <p>Size & Shipping</p>
              <p>Return & Exchanges</p>
              <p>FAQ</p>
            </router-link>
          </v-col>
          <v-col style="padding: 10px" :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
            <h4>Contact Us</h4>
            <p>Email: {{ BRAND_EMAIL }}</p>
            <p>Phone: {{ BRAND_PHONE }}</p>
          </v-col>
          <v-col style="padding: 10px" :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
            <h4>Socials</h4>
            <a :href="BRAND_FACEBOOK" target="_blank" rel="noopener">
              <i :style="{fontSize: '30px', padding: '5px', color: COLORS.FACEBOOK}" class="fa-brands fa-facebook"></i>
            </a>
            <a :href="BRAND_INSTAGRAM" target="_blank" rel="noopener">
              <i :style="{fontSize: '30px', padding: '5px', color: COLORS.INSTAGRAM}" class="fa-brands fa-instagram"></i>
            </a>
          </v-col>
        </v-row>
        <vs-row style="font-size: 18px">
          <p>{{ BRAND_NAME }}</p>
          <span class="material-symbols-outlined"> pin_drop </span>
          <p>Dhaka, Bangladesh.</p>
        </vs-row>
        <p style="font-size: 12px">
          Copyright © {{ new Date().getFullYear() }} {{ BRAND_NAME }}. All rights reserved.
        </p>
      </div>
    </v-sheet>
  </div>
</template>

<script>
import { COLORS } from '@/plugins/branding.js';
import { BRAND_NAME, BRAND_EMAIL, BRAND_FACEBOOK, BRAND_INSTAGRAM, BRAND_PHONE  } from '@/plugins/utils.js';

export default {
  name: 'Footer',
  data: () => ({
    COLORS,
    BRAND_NAME,
    BRAND_EMAIL,
    BRAND_FACEBOOK,
    BRAND_INSTAGRAM,
    BRAND_PHONE
  }),
};
</script>