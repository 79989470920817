<template>
  <div :style="$vuetify.breakpoint.smAndDown ? 'padding: 15% 5%' : 'padding: 6% 5%'
    ">
    <div>
      <v-list>
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title style="font-size: 24px">Orders</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list>
            <v-list-group>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 18px; padding-left: 5%">New Orders</v-list-item-title>
                </v-list-item-content>
              </template>
              <Orders style="max-height: 70vh; overflow-y: auto;" :orders="newOrders" :status="'Move to in Progress'" :commit="'updateOrderStatusToInProgress'" />
            </v-list-group>
            <v-list-group style="padding-bottom: 1%">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 18px; padding-left: 5%">In Progress</v-list-item-title>
                </v-list-item-content>
              </template>
              <Orders style="max-height: 70vh; overflow-y: auto;" :orders="inProgressOrders" :status="'Move to Delivered'"
                :commit="'updateOrderStatusToDelivered'" />
            </v-list-group>
            <v-list-group style="padding-bottom: 1%">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 18px; padding-left: 5%">Delivered Orders</v-list-item-title>
                </v-list-item-content>
              </template>
              <Orders style="max-height: 70vh; overflow-y: auto;" :orders="deliveredOrders.splice(0, 10)" :status="'Delivered'" :disabled="true" />
            </v-list-group>
          </v-list>
        </v-list-group>
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title style="font-size: 24px">Change Collection Name</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item style="padding-top: 2%">
            <v-card tile :style="{ backgroundColor: COLORS.PRIMARY }" width="100%">
              <div style="padding: 1%">
                <v-form>
                  <v-text-field v-model="collectionName" label="Collection Name" :rules="[rules.required]">
                  </v-text-field>
                  <v-btn rounded dark @click.prevent="updateCollection()">
                    Save
                  </v-btn>
                  <vs-row style="
                  padding: 10px;
                  padding: 20px;">
                    <p style="color: red" v-if="error">{{ this.errorMsg }}</p>
                    <p style="color: green" v-if="success">{{ this.msg }}</p>
                  </vs-row>
                </v-form>
              </div>
            </v-card>
          </v-list-item>
        </v-list-group>
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title style="font-size: 24px">Add a New Product</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item style="padding-top: 2%">
            <vs-row justify="center">
              <h5 style="text-align: center">Add Details</h5>
              <v-card tile :style="{ backgroundColor: COLORS.PRIMARY }" width="100%">
                <div style="padding: 2%">
                  <v-form>
                    <div v-if="files && files.length" class="image-container">
                      <v-img v-for="(image, index) in files" :key="index" :src="image.url" class="mx-2"
                        max-width="200"></v-img>
                    </div>
                    <v-text-field v-model="title" label="Add Title" :rules="[rules.required]" placeholder="Panjabi">
                    </v-text-field>
                    <v-text-field v-model="description" label="Add Description" placeholder="A perfect eid attire.">
                    </v-text-field>
                    <v-text-field v-model.number="price" :rules="[rules.required]" label="Add Price" type="number"
                      placeholder="1500">
                    </v-text-field>
                    <v-select dense :items="tagsList" v-model="tags" label="Add Tags" chips multiple>
                    </v-select>
                    <div style="display: flex; flex-direction: row; align-items: center">
                      <div v-for="(quantity, size) in sizeAndQuantity" :key="size" style="flex: 1; margin-right: 15px;">
                        <v-text-field v-model.number="sizeAndQuantity[size]" type="number" :label="`${size}`"
                          :rules="[rules.required]"></v-text-field>
                      </div>
                    </div>
                    <v-combobox dense :items="availableColors" v-model="colors" label="Add Colors" chips multiple>
                      <template slot="item" slot-scope="data">
                        <v-list-item-content>
                          <div> {{ data.item }}</div>
                        </v-list-item-content>
                        <v-list-item-action>
                          <div :style="{ width: '28px', height: '28px', backgroundColor: COLOR_MAP[data.item] }"></div>
                        </v-list-item-action>
                      </template>
                    </v-combobox>
                    <v-file-input multiple label="Select Images" v-model="files"
                      prepend-icon="mdi-camera"></v-file-input>
                    <v-btn rounded dark @click.prevent="addProduct()">
                      Add Product
                    </v-btn>
                    <vs-row :style="{ padding: '10px' }">
                      <p style="color: red" v-if="error">
                        {{ this.errorMsg }}
                      </p>
                      <p style="color: green" v-if="success">
                        {{ this.msg }}
                      </p>
                    </vs-row>
                  </v-form>
                </div>
              </v-card>
            </vs-row>
          </v-list-item>
        </v-list-group>
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title style="font-size: 24px">Edit Existing Products</v-list-item-title>
            </v-list-item-content>
          </template>
          <div style="padding-top: 2%; max-height: 70vh; overflow-y: auto;">
            <EditProducts :products="allProducts" />
          </div>
        </v-list-group>
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title style="font-size: 24px">Client Testimonials</v-list-item-title>
            </v-list-item-content>
          </template>
          <div style="padding: 2% 0">
            <div v-if="testimonials && testimonials.length">
              <v-row>
                <v-col v-for="(image, index) in testimonials" :key="index" cols="4">
                  <v-card>
                    <v-img :src="image" class="mx-2" max-width="100%"></v-img>
                    <v-card-actions>
                      <v-btn depressed dark rounded :color="COLORS.RED"
                        @click="deleteTestimonial(image, index)">Delete</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </div>
            <v-divider></v-divider>
            <div v-if="newTestimonials && newTestimonials.length">
              <v-row>
                <v-col v-for="(image, index) in newTestimonials" :key="index" cols="4">
                  <v-img :src="image.url" class="mx-2" max-width="100%"></v-img>
                </v-col>
              </v-row>
            </div>
          </div>
          <v-form>
            <v-file-input multiple label="Add Testimonials" v-model="newTestimonials"
              prepend-icon="mdi-camera"></v-file-input>
            <v-btn dark rounded @click.prevent="addTestimonials()">
              Save Changes
            </v-btn>
            <vs-row v-if="error || success" style=" padding: 10px 0;">
              <p :style="{ color: COLORS.RED }" v-if="error">
                {{ this.errorMsg }}
              </p>
              <p :style="{ color: COLORS.GREEN }" v-if="success">
                {{ this.msg }}
              </p>
            </vs-row>
          </v-form>
        </v-list-group>
      </v-list>
    </div>
  </div>
</template>
<script>
import 'firebase/compat/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import db from '../firebase/firebaseInit';
import EditProducts from '../components/EditProducts.vue';
import Orders from '../components/Orders.vue';
import { COLORS } from '@/plugins/branding.js';
import { AVAILABLE_COLORS, TAGS, COLOR_MAP } from '../plugins/utils';
import { toggleLoading } from '@/components/js/loading.js';
import { resizeImage } from '@/components/js/imageHelper.js';

export default {
  name: 'AdminPanel',
  components: { EditProducts, Orders },
  data: () => ({
    rules: {
      required: (value) => !!value || 'Required.',
    },
    COLORS,
    files: [],
    viewUrl: null,
    title: null,
    description: null,
    price: null,
    sizeAndQuantity: {
      "S": 0,
      "M": 0,
      "L": 0,
      "XL": 0,
      "XXL": 0,
    },
    colors: [],
    tags: [],
    newTestimonials: [],
    tagsList: TAGS,
    error: false,
    success: false,
    errorMsg: null,
    msg: null,
    availableColors: AVAILABLE_COLORS,
    COLOR_MAP
  }),
  watch: {
    tags() {
      if (this.tags.includes('Shirt')) {
        this.sizeAndQuantity = {
          "S": 0,
          "M": 0,
          "L": 0,
        };
      }
    },
    newTestimonials(newFiles) {
      this.newTestimonials = newFiles.map(file => ({
        file,
        url: URL.createObjectURL(file)
      }));
    },
    files(newFiles) {
      this.files = newFiles.map(file => ({
        file,
        url: URL.createObjectURL(file)
      }));
    }
  },
  computed: {
    newOrders() {
      return this.$store.state.newOrders;
    },
    inProgressOrders() {
      return this.$store.state.inProgressOrders;
    },
    deliveredOrders() {
      return this.$store.state.deliveredOrders.slice(0, 10);
    },
    profileId() {
      return this.$store.state.profileId;
    },
    allProducts() {
      return this.$store.state.products;
    },
    collectionName: {
      get() {
        return this.$store.state.collectionName;
      },
      set(payload) {
        this.$store.commit('changeCollectionName', payload);
      },
    },
    testimonials: {
      get() {
        return this.$store.state.testimonials;
      },
      set(payload) {
        this.$store.commit('updateTestimonials', payload);
      },
    }
  },
  methods: {
    async fileExists(docRef) {
      try {
        await docRef.getMetadata();
        return true;
      } catch (error) {
        if (error.code === 'storage/object-not-found') {
          return false;
        }
        throw error;
      }
    },
    async deleteTestimonial(image, index) {
      try {
        this.toggleLoading(true);
        const imageRef = firebase.storage().refFromURL(image);
        await imageRef.delete();
        const db = firebase.firestore();
        const testDb = db.collection('systemVariables').doc("3GbWEy68mVNbBVougaO4");
        await testDb.update({
          testimonials: firebase.firestore.FieldValue.arrayRemove(image)
        });
        this.testimonials.splice(index, 1);
        this.msg = 'Testimonial successfully deleted.';
        this.success = true;
        this.toggleLoading(false);
        setTimeout(() => {
          this.success = false;
        }, 5000);
      } catch (err) {
        this.errorMsg = err.message;
        this.toggleLoading(false);
        this.error = true;
        setTimeout(() => {
          this.error = false;
        }, 5000);
      }
    },
    async addTestimonials() {
      try {
        this.toggleLoading(true);
        const dataBase = db.collection('systemVariables').doc("3GbWEy68mVNbBVougaO4");
        const storageRef = firebase.storage().ref();
        console.log(this.newTestimonials);
        const uploadPromises = this.newTestimonials.map(async ({ file }, index) => {
          let uniqueIndex = index;
          let docRef = storageRef.child(`documents/Testimonials/${uniqueIndex}`);
          let exists = await this.fileExists(docRef);
          while (exists) {
            uniqueIndex++;
            docRef = storageRef.child(`documents/Testimonials/${uniqueIndex}`);
            exists = await this.fileExists(docRef);
          }
          const resizedFile = await resizeImage(file);
          await docRef.put(resizedFile);
          const url = await docRef.getDownloadURL();
          this.testimonials.push(url);
        });
        await Promise.all(uploadPromises);
        await dataBase.update({
          testimonials: this.testimonials,
        });
        this.msg = 'Successfully added testimonials.';
        this.newTestimonials = [];
        this.success = true;
        this.toggleLoading(false);
        setTimeout(() => {
          this.success = false;
        }, 15000);
      } catch (err) {
        console.error(err);
        this.errorMsg = err.message;
        this.toggleLoading(false);
        this.error = true;
        setTimeout(() => {
          this.error = false;
        }, 15000);
      }
    },
    updateCollection() {
      try {
        this.toggleLoading(true);
        this.$store.dispatch('updateCollection');
        this.msg = 'The collection has now been updated';
        this.success = true;
        this.toggleLoading(false);
        setTimeout(() => {
          this.success = false;
        }, 5000);
      } catch (err) {
        this.toggleLoading(false);
        this.errorMsg = err.message;
        this.error = true;
        setTimeout(() => {
          this.error = false;
        }, 5000);
      }
    },
    toggleLoading(isLoading) {
      toggleLoading(this, isLoading);
    },
    async addProduct() {
      try {
        if (
          this.title !== null &&
          this.price !== null &&
          this.files !== [] &&
          this.tags !== []
        ) {
          this.toggleLoading(true)
          const storageRef = firebase.storage().ref();
          const timeStamp = await Date.now();
          const dataBase = db.collection('products').doc();
          const imageUrls = [];
          const uploadPromises = this.files.map(async ({ file }, index) => {
            let uniqueIndex = index;
            let docRef = storageRef.child(`documents/ProductPhotos/${this.title}_${uniqueIndex}`);
            let exists = await this.fileExists(docRef);
            while (exists) {
              uniqueIndex++;
              docRef = storageRef.child(`documents/ProductPhotos/${this.title}_${uniqueIndex}`);
              exists = await this.fileExists(docRef);
            }
            const resizedFile = await resizeImage(file);
            await docRef.put(resizedFile);
            const url = await docRef.getDownloadURL();
            imageUrls.push(url);
          });
          await Promise.all(uploadPromises);
          await dataBase.set({
            productId: dataBase.id,
            title: this.title,
            description: this.description,
            price: this.price,
            sizeAndQuantity: this.sizeAndQuantity,
            colors: this.colors,
            images: imageUrls,
            tags: this.tags,
            profileId: this.profileId,
            date: timeStamp,
          });
          this.msg = 'You have successfully added the product to the database!';
          this.error = false;
          this.success = true;
          this.files = null;
          this.title = null;
          this.description = null;
          this.price = 0;
          this.sizeAndQuantity = {
            "S": 0,
            "M": 0,
            "L": 0,
            "XL": 0,
            "XXL": 0,
          },
            this.colors = [];
          this.tags = [];
          this.$store.dispatch('getProducts');
          this.toggleLoading(false)
          setTimeout(() => {
            this.success = false;
          }, 5000);
          return;
        }
        this.error = true;
        this.toggleLoading(false)
        this.errorMsg = 'Please fill out all the fields!';
        setTimeout(() => {
          this.error = false;
        }, 5000);
      } catch (err) {
        this.error = true;
        this.toggleLoading(false)
        this.errorMsg = err.message.replace('Firebase:', '');
        setTimeout(() => {
          this.error = false;
        }, 5000);
      }
    },
  },
};
</script>


<style>
.image-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
}
</style>
