<template>
  <div class="centergrid">
    <v-carousel v-if="homeSlider && homeSlider.length" cycle height="100vh" hide-delimiter-background hide-delimiters>
      <v-carousel-item v-for="item in homeSlider" :key="item.productId" :src="item.productImages[0]"
        cover></v-carousel-item>
      <div class="text-center position-absolute top-50 start-50 translate-middle">
        <router-link to="/collection">
          <v-btn :style="{ color: COLORS.WHITE, fontSize: '20px' }"
            :color="COLORS.PRIMARY" text rounded>
            SHOP {{ collectionName }}
          </v-btn>
        </router-link>
      </div>
    </v-carousel>
    <vs-row style="padding: 64px 0;" justify="center">
      <v-slide-group show-arrows>
        <v-slide-item v-if="panjabi" style="padding: 0 1px;">
          <router-link to="/panjabi">
            <v-card :width="$vuetify.breakpoint.smAndDown ? 260 : 350" height="400" tile flat>
              <v-img height="100%" v-bind:src="panjabi.productImages[0]" />
            </v-card>
            <div style="padding: 5% 0;">
                <h6> Panjabi </h6>
              </div>
          </router-link>
        </v-slide-item>
        <v-slide-item v-if="waistCoat" style="padding: 0 1px;">
          <router-link to="/waistCoat">
            <v-card :width="$vuetify.breakpoint.smAndDown ? 260 : 350" height="400" tile flat>
              <v-img height="100%" v-bind:src="waistCoat.productImages[0]" />
            </v-card>
            <div style="padding: 5% 0;">
                <h6> Waist Coat </h6>
              </div>
          </router-link>
        </v-slide-item>
        <v-slide-item v-if="shirt" style="padding: 0 1px;">
          <router-link to="/shirt">
            <v-card :width="$vuetify.breakpoint.smAndDown ? 260 : 350" height="400" tile flat>
              <v-img height="100%" v-bind:src="shirt.productImages[0]" />
            </v-card>
            <div style="padding: 5% 0;">
                <h6> Shirt </h6>
              </div>
          </router-link>
        </v-slide-item>
      </v-slide-group>
    </vs-row>
    <router-link v-if="bestSellers" to="/bestSellers">
      <div class="position-relative">
        <v-img :height="$vuetify.breakpoint.smAndDown ? '85vh' : '100vh'" v-bind:src="bestSellers.productImages[0]" />
        <div class="text-center position-absolute bottom-0 start-0"
          :style="{ width: '100%', padding: '10px', backdropFilter: 'blur(6px)' }">
          <p :style="{ fontSize: '24px', fontWeight: 'bold', color: COLORS.WHITE }">
            {{
              bestSellers.productTitle }}</p>
          <v-btn text
            :style="{ color: COLORS.WHITE, fontWeight: 'bold', fontSize: '16px' }" rounded>
            SHOP BEST SELLERS
          </v-btn>
        </div>
      </div>
    </router-link>
    <router-link v-if="collection" to="/collection">
      <div class="position-relative">
        <v-img :height="$vuetify.breakpoint.smAndDown ? '85vh' : '100vh'" v-bind:src="collection.productImages[0]" />
        <div class="text-center position-absolute bottom-0 start-0"
          :style="{ width: '100%', padding: '10px', backdropFilter: 'blur(6px)'}">
          <p :style="{ fontSize: '24px', fontWeight: 'bold', color: COLORS.WHITE }">
            {{
              collection.productTitle }}</p>
          <v-btn text
            :style="{ color: COLORS.WHITE, fontWeight: 'bold', fontSize: '16px'}" rounded>
            SHOP {{ collectionName }}
          </v-btn>
        </div>
      </div>
    </router-link>
    <div v-if="testimonials && testimonials.length">
      <p :style="{ color: COLORS.BLACK, fontWeight: 'bold', fontSize: '28px', paddingTop: '5%', textAlign: 'center' }">
        Client Testimonials</p>
      <vs-row justify="center">
        <v-slide-group show-arrows>
          <v-slide-item v-for="item in testimonials" v-bind:key="item" style="padding: 0 2px;">
            <v-card width="300" tile flat>
              <v-img width="100%" v-bind:src="item" @click="openImage(item)" />
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </vs-row>
      <v-dialog v-model="dialog" max-width="600px">
        <v-img :src="selectedImage" />
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { COLORS } from '@/plugins/branding.js';

export default {
  name: 'Home',
  data: () => ({
    COLORS,
    dialog: false,
    selectedImage: null,
  }),
  components: {
  },
  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content: "Welcome to SADI, a brand that brings tradition and luxury together with a modern touch. Discover our high-end collection, where quality meets style, from elegant Panjabis to sophisticated waistcoats and classic Cuban shirts. Each piece is crafted with the finest fabrics, detailed embroidery, and timeless designs that evoke a sense of joy, success, and elegance. Experience the essence of luxury with SADI and find the perfect attire that makes you feel both traditional and trendy.",
        },
        {
          name: 'keywords',
          content: "Premium eastern clothing, Panjabi, Zardozi Panjabi, High-end traditional fashion, Luxury ethnic wear, Embroidery Panjabi, South Asian fashion",
        },
        {
          property: 'og:title',
          content: "SADI - where timeless elegance meets traditional flair",
        },
        {
          property: 'og:description',
          content: "Welcome to SADI, a brand that brings tradition and luxury together with a modern touch. Discover our high-end collection, where quality meets style, from elegant Panjabis to sophisticated waistcoats and classic Cuban shirts. Each piece is crafted with the finest fabrics, detailed embroidery, and timeless designs that evoke a sense of joy, success, and elegance. Experience the essence of luxury with SADI and find the perfect attire that makes you feel both traditional and trendy.",
        }
      ]
    }
  },
  methods: {
    openImage(image) {
      this.selectedImage = image;
      this.dialog = true;
    },
  },
  computed: {
    collectionName() {
      return this.$store.state.collectionName;
    },
    testimonials() {
      return this.$store.state.testimonials;
    },
    bestSellers() {
      return this.$store.state.bestSellers.slice(0, 1)[0];
    },
    collection() {
      return [this.$store.state.newCollection[this.$store.state.newCollection.length - 1]][0];
    },
    homeSlider() {
      return this.$store.state.newCollection.slice(0, 3);
    },
    panjabi() {
      return this.$store.state.panjabis[0];
    },
    waistCoat() {
      return this.$store.state.waistCoats[0];
    },
    shirt() {
      return this.$store.state.shirts[0];
    },
  },
};
</script>