import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import db from '../firebase/firebaseInit';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    products: [],
    bag: [],
    allOrders: [],
    
    newOrders: [],
    inProgressOrders: [],
    deliveredOrders: [],
    
    bestSellers: [],
    newCollection: [],
    panjabis: [],
    shirts: [],
    waistCoats: [],
    
    collectionName: null,
    testimonials: [],

    productLoaded: null,
    productTitle: null,
    productDescription: null,
    productPrice: null,
    productSizeAndQuantity: null,
    productColors: null,
    productImages: null,
    productTags: null,
    
    user: null,
    profileAdmin: null,
    profileEmail: null,
    profileFirstName: null,
    profileLastName: null,
    profileAddress: null,
    profilePhone: null,
    profilePhone2: null,
    profileId: null,
  },
  getters: {
  },
  mutations: {
    removeItemFromBag(state, payload) {
        state.bag = state.bag.filter(
          (post) => post.productId !== payload.productId || post.selectedColor !== payload.selectedColor || post.size !== payload.size,
        );
    },
    emptyBag(state) {
      state.bag = [];
    },
    removeAdmin(state) {
      state.profileAdmin = null;
    },
    newProduct(state, payload) {
      state.product = payload;
    },
    updateProductTitle(state, payload) {
      state.productTitle = payload;
    },
    updateProductDescription(state, payload) {
      state.productDescription = payload;
    },
    updateProductPrice(state, payload) {
      state.productPrice = payload;
    },
    updateProductSizeAndQuantity(state, payload) {
      state.productSizeAndQuantity = payload;
    },
    updateProductColors(state, payload) {
      state.productColors = payload;
    },
    updateProductImages(state, payload) {
      state.productImages = payload;
    },
    updateProductTags(state, payload) {
      state.productTags = payload;
    },
    setProductState(state, payload) {
      state.productTitle = payload.productTitle;
      state.productDescription = payload.productDescription;
      state.productPrice = payload.productPrice;
      state.productSizeAndQuantity = payload.productSizeAndQuantity;
      state.productColors = payload.productColors;
      state.productImages = payload.productImages;
      state.productTags = payload.productTags;
    },
    updateUser(state, payload) {
      state.user = payload;
    },
    addToBag(state, payload) {
      let itemFound = false;
      state.bag.forEach((product) => {
        if (product.productId === payload.productId && product.selectedColor === payload.selectedColor && product.size === payload.size) {
          product.quantity += payload.quantity;
          itemFound = true;
        }
      });
      if (!itemFound) {
        state.bag.push(payload);
      }
    },
    setProfileInfo(state, doc) {
      state.profileId = doc.id;
      state.profileEmail = doc.data().email;
      state.profileFirstName = doc.data().firstName;
      state.profileLastName = doc.data().lastName;
      state.profileAddress = doc.data().address;
      state.profilePhone = doc.data().phone;
      state.profilePhone2 = doc.data().phone;
      state.profileAdmin = doc.data().admin;
    },
    setSystemVariables(state, doc) {
      state.collectionName = doc.data().collectionName;
    },
    setTestimonials(state, doc) {
        state.testimonials = doc.data().testimonials;
    },
    changeCollectionName(state, payload) {
      state.collectionName = payload;
    },
    updateTestimonials(state, payload) { 
      state.testimonials = payload;
    },
    changeFirstName(state, payload) {
      state.profileFirstName = payload;
    },
    changeLastName(state, payload) {
      state.profileLastName = payload;
    },
    changeAddress(state, payload) {
      state.profileAddress = payload;
    },
    changePhone(state, payload) {
      state.profilePhone = payload;
    },
    changePhone2(state, payload) {
      state.profilePhone2 = payload;
    },
    changeEmail(state, payload) {
      state.profileEmail = payload;
    },
  },
  actions: {
    async getCurrentUser({ commit }) {
      const dataBase = await db
        .collection('users')
        .doc(firebase.auth().currentUser.uid);
      const dbResults = await dataBase.get();
      commit('setProfileInfo', dbResults);
    },
    async getSystemVariabels({ commit }) {
      const dataBase = await db.collection('systemVariables').doc('mAXY6jU9A0ZgNL3A6KkQ');
      const dbResults = await dataBase.get();
      commit('setSystemVariables', dbResults);
    },
    async getTestimonials({ commit }) {
      const dataBase = await db.collection('systemVariables').doc('3GbWEy68mVNbBVougaO4');
      const dbResults = await dataBase.get();
      commit('setTestimonials', dbResults);
    },
    async getOrders({ state }) {
      state.newOrders = [];
      state.inProgressOrders = [];
      state.deliveredOrders = [];
      state.allOrders = [];
      const dataBase = await db.collection('orders').orderBy('orderDate', 'desc');
      const dbResults = await dataBase.get();
      dbResults.forEach((doc) => {
        const data = {
          orderId: doc.data().orderId,
          orderDate: new Date(doc.data().orderDate).toLocaleDateString(),
          profileId: doc.data().profileId,
          firstName: doc.data().firstName,
          address: doc.data().address,
          phone: doc.data().phone,
          orderedProducts: doc.data().orderedProducts,
          orderTotal: doc.data().orderTotal,
          status: doc.data().status,
          paymentMethod: doc.data().paymentMethod,
          bkashNumber: doc.data().bkashNumber,
        };
        state.allOrders.push(data);
        if (doc.data().status === 'Received') {
          state.newOrders.push(data);
        }
        if (doc.data().status === 'In Progress') {
          state.inProgressOrders.push(data);
        }
        if (doc.data().status === 'Delivered') {
          state.deliveredOrders.push(data);
        }
      });
    },
    async getProducts({ state }) {
      const dataBase = await db.collection('products').orderBy('date', 'desc');
      const dbResults = await dataBase.get();
      dbResults.forEach((doc) => {
        if (!state.products.some((post) => post.productId === doc.id)) {
          const data = {
            productId: doc.data().productId,
            productColors: doc.data().colors,
            productDescription: doc.data().description.trim(),
            productTitle: doc.data().title.trim(),
            productPrice: doc.data().price,
            productTags: doc.data().tags,
            productDate: doc.data().date,
            productSizeAndQuantity: doc.data().sizeAndQuantity,
            productImages: doc.data().images,
          };
          state.products.push(data);
          if (doc.data().tags.includes('Best Seller')) 
            state.bestSellers.push(data);
          if (doc.data().tags.includes('New Collection')) 
            state.newCollection.push(data);
          if (doc.data().tags.includes('Panjabi')) 
            state.panjabis.push(data);
          if (doc.data().tags.includes('Shirt')) 
            state.shirts.push(data);
          if (doc.data().tags.includes('Waist Coat')) 
            state.waistCoats.push(data);
        }
      });
      state.productLoaded = true;
    },
    async deleteProduct({ commit }, payload) {
      const getProduct = await db.collection('products').doc(payload);
      await getProduct.delete();
      commit('productDeleted');
      this.dispatch('getProducts');
    },
    async updateOrderStatusToInProgress({ commit }, payload) {
      const dataBase = await db.collection('orders').doc(payload);
      await dataBase.update({
        status: 'In Progress',
      });
      commit('setOrderStatusToInProgress');
      this.dispatch('getOrders');
    },
    async updateOrderStatusToDelivered({ commit }, payload) {
      const dataBase = await db.collection('orders').doc(payload);
      await dataBase.update({
        status: 'Delivered',
      });
      this.dispatch('getOrders');
    },
    async updateProductQuantity({ commit, state}, payload) {
      const dataBase = await db.collection('products').doc(payload.productId);
      const prod = state.products.find(product => product.productId === payload.productId);
      prod.productSizeAndQuantity[payload.size] = prod.productSizeAndQuantity[payload.size] -= payload.quantity;
      await dataBase.update({
        sizeAndQuantity: prod.productSizeAndQuantity,
      });
    },
    async updateUserSettings({ commit, state }) {
      const dataBase = await db.collection('users').doc(state.profileId);
      await dataBase.update({
        firstName: state.profileFirstName,
        lastName: state.profileLastName,
        address: state.profileAddress,
        phone: state.profilePhone,
        phone2: state.profilePhone2,
      });
    },
    async updateCollection({ commit, state }) {
      const dataBase = await db.collection('systemVariables').doc('mAXY6jU9A0ZgNL3A6KkQ');
      await dataBase.update({
        collectionName: state.collectionName,
      });
      commit('setCollection');
    },
  },
  modules: {},
  plugins: [
    createPersistedState({
      paths: ['bag'],
    }),
  ],
});
