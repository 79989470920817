import Pica from 'pica';

const pica = Pica();

export async function resizeImage(file) {
  const img = document.createElement('img');
  img.src = URL.createObjectURL(file);
  await new Promise(resolve => (img.onload = resolve));
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const MAX_WIDTH = 800;
  const scaleSize = MAX_WIDTH / img.width;
  canvas.width = MAX_WIDTH;
  canvas.height = img.height * scaleSize;
  ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  let resizedBlob = await pica.resize(img, canvas, {
    quality: 3,
    alpha: true
  }).then(result => pica.toBlob(result, 'image/jpeg', 0.8));
  let quality = 0.8;
  while (resizedBlob.size > 100 * 1024 && quality > 0.1) {
    resizedBlob = await pica.toBlob(canvas, 'image/jpeg', quality);
    quality -= 0.1;
  }
  return new File([resizedBlob], file.name, { type: 'image/jpeg' });
}