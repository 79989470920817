<template>
  <div :style="$vuetify.breakpoint.smAndDown ? 'padding: 10% 5%' : 'padding: 5% 5%'">
    <div class="d-flex flex-column align-items-center" v-if="bagEmpty" :style="{ padding: '5% 0%' }">
      <div style="font-size: 32px">Empty Bag!</div>
      <div style="font-size: 24px">Let's get you shopping!</div>
      <vs-row style="padding-top: 2%" justify="center">
        <YouMayLike :products="availableProducts" />
      </vs-row>
    </div>
    <vs-row style="padding: 2% 0%" v-if="!bagEmpty">
      <router-link style="text-align: center; padding: 14px;" v-if="!user" :to="{ name: 'Login' }">Log
        in to track your order status in real-time and enjoy a smoother shopping experience!
      </router-link>
      <vs-col v-if="$vuetify.breakpoint.smAndDown" :w="12">
        <vs-row style="padding: 5% 0">
          <v-btn :disabled="paymentType === 'bkash' && (!bkashNumber || bkashNumber === null)" dark
            style="width: 100%; padding: 1%" rounded @click.prevent="placeOrder()">
            Place Order
          </v-btn>
        </vs-row>
        <h4>Order Summary</h4>
        <v-divider></v-divider>
        <vs-row justify="space-between">
          <vs-col style="text-align: left" w="6"> Subtotal </vs-col>
          <vs-col style="color: gray; text-align: right" w="6">
            {{ subTotal }} BDT.
          </vs-col>
        </vs-row>
        <v-divider></v-divider>
        <vs-row justify="space-between">
          <vs-col style="text-align: left" w="6">Shipping Fee </vs-col>
          <vs-col style="color: gray; text-align: right" w="6">
            {{ shipping }} BDT.</vs-col>
        </vs-row>
        <v-divider></v-divider>
        <vs-row justify="space-between">
          <vs-col style="text-align: left; font-size: 17px; font-weight: bold" w="6">Total
          </vs-col>
          <vs-col style="text-align: right; font-size: 17px; font-weight: bold" w="6">
            {{ totalPrice }} BDT.</vs-col>
        </vs-row>
        <vs-row v-if="error" style="
              color: red;
              padding-top: 10%;
            ">
          <p>{{ errorMsg }}</p>
        </vs-row>
      </vs-col>
      <vs-col :w="$vuetify.breakpoint.smAndDown ? 12 : 7" :style="$vuetify.breakpoint.smAndDown ? 'padding-top: 24px;':''">
        <div style="padding: 2%">
          <h3>Shipping Information</h3>
          <v-text-field v-model="firstName" :rules="[rules.required]" label="First Name" required></v-text-field>
          <v-text-field v-model="lastName" label="Last Name"></v-text-field>
          <v-text-field :disabled="!!user" v-model="email" label="E-mail"></v-text-field>
          <v-text-field v-model="address" :rules="[rules.required]" label="Shipping Address"></v-text-field>
          <v-text-field v-model="phone" :rules="[rules.required]" label="Phone No."></v-text-field>
          <v-text-field v-model="phone2" label="Alternate Phone No."></v-text-field>
          <vs-row v-if="successProfile" style="
            color: green;
            padding: 20px;
          ">
            <p>{{ profileMsg }}</p>
          </vs-row>
          <vs-row v-if="errorProfile" style="
            color: crimson;
            padding: 20px;
          ">
            <p>{{ profileErrMsg }}</p>
          </vs-row>
          <vs-row justify="space-between">
            <v-switch v-model="dhaka" label="Inside Dhaka"></v-switch>
            <v-btn v-if="user" dark rounded @click.prevent="updateProfile()">
              Update Information
            </v-btn>
          </vs-row>
        </div>
        <div style="padding: 2%">
          <h3>Payment Information</h3>
          <v-radio-group dense v-model="paymentType">
            <v-radio append-icon="https://cdn-icons-png.flaticon.com/512/306/306552.png" :color="COLORS.SECONDARY"
              label="Cash on Delivery" value="cash">
            </v-radio>
            <v-radio :color="COLORS.SECONDARY" label="Bkash Payment" value="bkash">
            </v-radio>
          </v-radio-group>
          <div v-if="paymentType === 'bkash'">
            <v-snackbar top rounded="pill" v-model="snackbar">
              Bkash number copied to clipboard
              <template v-slot:action="{ attrs }">
                <v-btn dark text rounded v-bind="attrs" @click="snackbar = false">
                  Close
                </v-btn>
              </template>
            </v-snackbar>
            <vs-row>
              <vs-col w="10">
                <p :style="{ fontWeight: 'bold' }">Scan the QR Code or use the number below to make Bkash Payment of {{
                  totalPrice }} BDT.</p>
              </vs-col>
              <vs-col w="2">
                <div @click="copyToClipboard(ownerNumber)" style="cursor: pointer;">
                  <span v-if="!copied" class="material-symbols-outlined">
                    content_copy
                  </span>
                  <span v-if="copied" class="material-symbols-outlined">
                    check_circle
                  </span>
                </div>
              </vs-col>
            </vs-row>
            <img width="80%" src="../assets/bkashQr.png" />
            <v-text-field v-model="bkashNumber" :rules="[rules.required]"
              label="Enter Bkash Transaction ID or Your Bkash Number" required></v-text-field>
          </div>
        </div>
        <Bag :products="bag" :height="'150px'" />
        <vs-row v-if="$vuetify.breakpoint.smAndDown" style="padding: 2%">
          <v-btn :disabled="paymentType === 'bkash' && (!bkashNumber || bkashNumber === null)" dark
            style="width: 100%; padding: 1%" rounded @click.prevent="placeOrder()">
            Place Order
          </v-btn>
        </vs-row>
      </vs-col>
      <vs-col v-if="!$vuetify.breakpoint.smAndDown" :w="4">
        <v-list>
          <h4>Order Summary</h4>
          <v-divider></v-divider>
          <vs-row justify="space-between">
            <vs-col style="text-align: left" w="6"> Subtotal </vs-col>
            <vs-col style="color: gray; text-align: right" w="6">
              {{ subTotal }} BDT.
            </vs-col>
          </vs-row>
          <v-divider></v-divider>
          <vs-row justify="space-between">
            <vs-col style="text-align: left" w="6">Shipping Fee </vs-col>
            <vs-col style="color: gray; text-align: right" w="6">
              {{ shipping }} BDT.</vs-col>
          </vs-row>
          <v-divider></v-divider>
          <vs-row justify="space-between">
            <vs-col style="text-align: left; font-size: 17px; font-weight: bold" w="6">Total
            </vs-col>
            <vs-col style="text-align: right; font-size: 17px; font-weight: bold" w="6">
              {{ totalPrice }} BDT.</vs-col>
          </vs-row>
          <vs-row v-if="error" style="
              color: red;
              padding-top: 10%;
            ">
            <p>{{ errorMsg }}</p>
          </vs-row>
          <vs-row style="padding-top: 5%">
            <v-btn :disabled="paymentType === 'bkash' && (!bkashNumber || bkashNumber === null)" dark
              style="width: 100%; padding: 1%" rounded @click.prevent="placeOrder()">
              Place Order
            </v-btn>
          </vs-row>
        </v-list>
      </vs-col>
    </vs-row>
    <vs-dialog prevent-close not-close width="40%" square v-model="orderComplete">
      <div class="con-content">
        <div :style="{ paddingBottom: '1%', fontSize: '20px', color: COLORS.SECONDARY }">
          Order Placed, thanks!
        </div>
        <div v-if="this.email">
          An email confirmation for your order will be sent shortly. Please allow a few minutes for it to arrive. If you do not see it, please check your spam or junk folder.
        </div>
        <v-divider></v-divider>
        <div style="font-weight: bold">Shipping to {{ this.firstName }},</div>
        <div>
          {{ this.address }} <br />
          Phone: {{ this.phone }}, {{ this.phone2 }}
        </div>
        <v-divider></v-divider>
        <div>
          Please reach out to use regarding any question using email or social
          media.
        </div>
        <div style="padding-bottom: 1%; font-weight: bold">
          Hope to see you again soon!
        </div>
        <div style="padding-bottom: 1%; font-weight: bold">
          -{{ BRAND_NAME }} Team.
        </div>
        <div></div>
      </div>
      <template #footer>
        <div>
          <v-btn dark rounded style="padding: 0.5% 2%" @click.prevent="closeModal()">
            Ok
          </v-btn>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>
<script>
import YouMayLike from '../components/YouMayLike.vue';
import Bag from '../components/Bag.vue';
import 'firebase/compat/firestore';
import db from '../firebase/firebaseInit';
import { COLORS } from '@/plugins/branding.js';
import { DHAKA_DELIVERY_FEE, OUTSIDE_DHAKA_DELIVERY_FEE, BRAND_NAME, BKASH_NUMBER, BRAND_EMAIL } from '@/plugins/utils.js';
import { toggleLoading } from '@/components/js/loading.js';
import { emailTemplate } from '@/components/js/emailTemplate.js';

export default {
  name: 'Checkout',
  components: { Bag, YouMayLike },
  data() {
    return {
      rules: {
        required: (value) => !!value || 'Required.',
      },
      orderComplete: false,
      snackbar: false,
      copied: false,
      dhaka: false,
      shipping: OUTSIDE_DHAKA_DELIVERY_FEE,
      success: false,
      successProfile: false,
      errorProfile: false,
      error: false,
      ownerNumber: BKASH_NUMBER,
      profileErrMsg: null,
      profileMsg: 'Shipping information has been succesfully updated.',
      paymentType: "cash",
      msg: null,
      bkashNumber: null,
      errMsg: 'Shipping information cannot be empty!',
      COLORS,
      DHAKA_DELIVERY_FEE,
      OUTSIDE_DHAKA_DELIVERY_FEE,
      BRAND_NAME,
      BRAND_EMAIL
    };
  },
  watch: {
    dhaka() {
      if (!this.dhaka) {
        this.shipping = this.OUTSIDE_DHAKA_DELIVERY_FEE;
      } else {
        this.shipping = this.DHAKA_DELIVERY_FEE;
      }
    },
  },
  computed: {
    profileId() {
      return this.$store.state.profileId;
    },
    availableProducts() {
      return this.$store.state.products;
    },
    user() {
      return this.$store.state.user;
    },
    bag() {
      return this.$store.state.bag;
    },
    bagEmpty() {
      return this.bag.length === 0;
    },
    subTotal() {
      let value = 0;
      this.bag.forEach((item) => {
        value += Number(item.productPrice) * item.quantity;
      });
      return value;
    },
    firstName: {
      get() {
        return this.$store.state.profileFirstName;
      },
      set(payload) {
        this.$store.commit('changeFirstName', payload);
      },
    },
    lastName: {
      get() {
        return this.$store.state.profileLastName;
      },
      set(payload) {
        this.$store.commit('changeLastName', payload);
      },
    },
    email: {
      get() {
        return this.$store.state.profileEmail;
      },
      set(payload) {
        this.$store.commit('changeEmail', payload);
      },
    },
    address: {
      get() {
        return this.$store.state.profileAddress;
      },
      set(payload) {
        this.$store.commit('changeAddress', payload);
      },
    },
    phone: {
      get() {
        return this.$store.state.profilePhone;
      },
      set(payload) {
        this.$store.commit('changePhone', payload);
      },
    },
    phone2: {
      get() {
        return this.$store.state.profilePhone2;
      },
      set(payload) {
        this.$store.commit('changePhone2', payload);
      },
    },
    totalPrice() {
      return this.subTotal + this.shipping;
    },
  },
  methods: {
    copyToClipboard(text) {
      navigator.clipboard.writeText(text)
      this.copied = true;
      this.snackbar = true;
      setTimeout(() => {
        this.copied = false;
      }, 5000);
    },
    closeModal() {
      this.orderComplete = false;
      this.$store.commit('emptyBag');
      this.$router.push({ name: 'Home' });
    },
    updateBag() {
      this.bag = this.$store.state.bag;
    },
    updateProfile() {
      try {
        this.$store.dispatch('updateUserSettings');
        this.successProfile = true;
      } catch (err) {
        this.profileErrMsg = err.message;
        this.errorProfile = true;
      }
    },
    toggleLoading(isLoading) {
      toggleLoading(this, isLoading);
    },
    async placeOrder() {
      try {
        if (
          this.firstName !== null &&
          this.address !== null &&
          this.phone !== null
        ) {
          this.toggleLoading(true);
          const date = await Date.now();
          const products = [];
          this.bag.forEach((item) => {
            this.$store.dispatch('updateProductQuantity', item);
            products.push({
              productId: item.productId,
              productImage: item.productImage,
              productTitle: item.productTitle,
              productPrice: item.productPrice,
              quantity: item.quantity,
              color: item.selectedColor,
              size: item.size,
            });
          });
          const dataBase = db.collection('orders').doc();
          await dataBase.set({
            orderId: dataBase.id,
            orderDate: date,
            profileId: this.profileId,
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            address: this.address,
            phone: this.phone,
            phone2: this.phone2,
            orderedProducts: products,
            orderTotal: this.totalPrice,
            paymentMethod: this.paymentType,
            bkashNumber: this.bkashNumber,
            status: 'Received',
          });
          let emailTitle = 'Order Confirmation';
          let note = 'Thank you for your order!';
          if (this.email) {
            this.emailBody = emailTemplate(emailTitle, note, dataBase.id, this.totalPrice, this.paymentType, this.address, this.email, this.phone, products);
            this.sendEmail(this.email, emailTitle);
          }
          emailTitle = 'New Order from ' + this.firstName;
          note = this.firstName + ' has placed an order.';
          this.emailBody = emailTemplate(emailTitle, note, dataBase.id, this.totalPrice, this.paymentType, this.address, this.email, this.phone, products);
          this.sendEmail(BRAND_EMAIL, emailTitle);

          this.toggleLoading(false);
          this.error = false;
          this.orderComplete = true;
          this.$store.dispatch('getOrders');
          this.$store.dispatch('getProducts');
        } else {
          this.toggleLoading(false);
          this.error = true;
          this.errorMsg =
            'Name, Address, Phone No, and Alternate Phone No cannot be empty';
        }
      } catch (err) {
        this.toggleLoading(false);
        this.error = true;
        this.errorMsg = err.message.replace('Firebase:', '');
      }
    },
    async sendEmail(to, subject) {
      try {
        const response = await fetch('https://sadi-server.vercel.app/api/send-email', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            to: to,
            subject: subject,
            html: String(this.emailBody)
          })
        });
        if (response.ok) {
          console.log('Email sent successfully');
        } else {
          console.error('Error sending email');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  },
};
</script>

<style>
.floating-alert {
  position: fixed;
  bottom: 70px;
  right: 20px;
  z-index: 1000;
  font-weight: bold;
}
</style>