<template>
  <div :style="$vuetify.breakpoint.smAndDown ? 'padding: 15% 5%' : 'padding: 6% 5%'">
    <PageBreadcrumbs :data="breadcrumbs" />
    <vs-row justify="center">
      <h1>Shirt</h1>
    </vs-row>
    <Products :products="shirts" />
  </div>
</template>
<script>

import PageBreadcrumbs from '../components/PageBreadcrumbs.vue';
import Products from '../components/Products.vue';

export default {
  name: 'Shirt',
  components: { PageBreadcrumbs, Products },
  data: () => ({
    breadcrumbs: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
      {
        text: 'Shirt',
        disabled: true,
      },
    ],
  }),
  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content: "Step into the world of old-money luxury with our exclusive Cuban shirts. Designed to give you that classic, elegant vibe, our Cuban shirts are crafted from the finest fabrics to ensure a comfortable yet refined look. Perfect for both casual and semi-formal settings, these shirts are all about making a statement with timeless style.",
        },{
          name: 'keywords',
          content: "Luxury Cuban shirts, Old money fashion, Classic men's shirts, Premium quality Cuban shirt, Stylish casual wear for men",
        },
        {
          property: 'og:title',
          content: "SADI - where timeless elegance meets traditional flair",
        },
        {
          property: 'og:description',
          content: "Step into the world of old-money luxury with our exclusive Cuban shirts. Designed to give you that classic, elegant vibe, our Cuban shirts are crafted from the finest fabrics to ensure a comfortable yet refined look. Perfect for both casual and semi-formal settings, these shirts are all about making a statement with timeless style.",        }
      ]
    }
  },
  computed: {
    shirts() {
      return this.$store.state.shirts;
    },
  },
};
</script>
