<template>
  <vs-row justify="center" align="center" :style="{ backgroundColor: colors.PRIMARY}">
    <div>
      <form>
        <v-container>
          <vs-row justify="center">
            <h4 :style="{ padding: '30px' }">Let's get you started!</h4>
          </vs-row>
          <vs-row justify="center">
            <vs-input label="First Name" :style="{ padding: '5px' }" v-model="firstName">
              <template #icon>
                <span class="material-symbols-outlined"> person </span>
              </template>
            </vs-input>
            <vs-input label="Last Name" :style="{ padding: '5px' }" v-model="lastName">
              <template #icon>
                <span class="material-symbols-outlined"> person </span>
              </template>
            </vs-input>
            <vs-input label="Email Address" :style="{ padding: '5px' }" v-model="email">
              <template #icon>
                <span class="material-symbols-outlined"> badge </span>
              </template>
            </vs-input>
            <vs-input width="100%" label="Password" :style="{ padding: '5px' }" type="password" v-model="password">
              <template #icon>
                <span class="material-symbols-outlined"> vpn_key </span>
              </template>
            </vs-input>
          </vs-row>
            <p v-if="error" :style="{ padding: '10px', color: colors.RED, textAlign: 'center'}">{{ this.errorMsg }}</p>
          <vs-row justify="center" :style="{ padding: '10px' }">
            <v-btn rounded @click.prevent="register()" dark :color="colors.BLACK" type="submit">
              Sign Up
            </v-btn>
          </vs-row>
          <vs-row justify="center">
            <router-link :to="{ name: 'Login' }">Already have an account?
            </router-link>
          </vs-row>
        </v-container>
      </form>
    </div>
  </vs-row>
</template>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import db from '../firebase/firebaseInit';
import { COLORS } from '@/plugins/branding.js';

export default {
  name: 'Register',
  data: () => ({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    address: '',
    phone: '',
    error: '',
    errorMsg: '',
    colors: COLORS,
  }),
  methods: {
    async register() {
      try {
        if (
          this.email !== '' &&
          this.password !== '' &&
          this.firstName !== '' &&
          this.lastName !== ''
        ) {
          this.error = false;
          this.errorMsg = '';
          const firebaseAuth = await firebase.auth();
          const createUser = await firebaseAuth.createUserWithEmailAndPassword(
            this.email,
            this.password
          );
          const result = await createUser;
          const dataBase = db.collection('users').doc(result.user.uid);
          await dataBase.set({
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            address: this.address,
            phone: this.phone,
          });
          this.$router.push({ name: 'Home' });
          return;
        }
        this.error = true;
        this.errorMsg = 'Please fill out all the fields!';
      } catch (err) {
        this.error = true;
        this.errorMsg = err.message.replace('Firebase:', '');
      }
    },
  },
};
</script>